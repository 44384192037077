<template>
  <div class="box">
    <div class="box_top">
      <div class="content_head">
        <div class="center_dq content_head_center">
          <p>Smile UP主站</p>
          <p>首页</p>
        </div>
      </div>
      <div class="content_top">
        <div class="content_top_content center_dq">
          <div class="content_top_left">
            <img src="../assets/app_logo.png" alt="" draggable="false">
            <div class="content_top_left_bottom">
              <p>中投数字资产管理（湖北）有限公司</p>
              <p>Cic Digital Asset Management (Hubei) Co., LTD</p>
            </div>
          </div>
          <div class="content_top_center">
            <ul>
              <!-- @mouseleave="MousLeDh(item,index)" @mouseover="MousDh(item, index)" -->
              <li @click="handleDh(index)" v-for="(item, index) in dhList" :key="index"
                :class="{ activeColor: colorindex == index }">{{ item }}</li>
              <!-- , MousDhcolor: MousDhindex == index,MousLeDhColor:MousDhLeindex==index -->
            </ul>
            <!-- <ul>
              <li class="activeColor">首页</li>
              <li>个人服务</li>
              <li>企业专区</li>
              <li>新闻中心</li>
              <li>联系我们</li>
            </ul> -->
          </div>
          <!-- <div class="content_top_right"></div> -->
        </div>
      </div>
    </div>
    <div class="content_center">
      <div class="content_center_top center_dq" style="margin-top: 140px;">
        <el-carousel height="330px">
          <el-carousel-item>
            <img src="../assets/swiper1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/swiper2.png" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content_center_Gsjs center_dq">
        <p>中投数字资产管理有限公司成立于2022年，是一家专注于跨境支付业务、数字资产处置以及支付技术解决方案的公司。我们在跨境支付领域拥有丰富的经验和专业的团队，致力于为客户提供高效、安全的跨境支付服务。
作为专业的跨境支付服务提供商，我们与国内外多家知名支付机构保持紧密合作，能够为客户提供全球范围内的跨境支付解决方案。无论是企业还是个人客户，我们都可以为他们提供快速、安全的跨境支付服务，帮助他们轻松实现跨境交易。
我们拥有先进的支付技术和风险控制系统，能够确保跨境支付过程的安全性和稳定性。同时，我们提供多种支付方式和灵活的结算工具，为客户提供了更加便捷的跨境支付体验。
作为专业的数字资产处置公司我们涵盖数字资产的评估、收购、处置等各个环节，通过专业的处置流程和严格的风险控制，确保政府罚没资产得到妥善处置。同时，我们严格遵守法律法规，确保处置过程合法合规。
中投数字资产管理有限公司将继续致力于跨境支付领域的创新和发展，为客户提供更加专业、优质的跨境支付服务。我们期待与您携手合作，共同开创跨境支付领域的美好未来。</p>
      </div>
      <div class="content_center_content center_dq">
        <!-- <img src="../assets/01.png" alt="" draggable="false">
        <img src="../assets/02.png" alt="" draggable="false">
        <img src="../assets/03.png" alt="" draggable="false">
        <img src="../assets/04.png" alt="" draggable="false">
        <img src="../assets/05.png" alt="" draggable="false">
        <img src="../assets/06.png" alt="" draggable="false">
        <img src="../assets/07.png" alt="" draggable="false"> -->

        <img src="../assets/01.png" alt="" id="jieshao" draggable="false">
        <img src="../assets/02.png" alt="" id="yingyongchangjing" draggable="false">
        <img src="../assets/03.png" alt="" id="shoufeibz" draggable="false">
        <img src="../assets/04_4.png" alt="" id="czzn" draggable="false">
        <!-- <img src="../assets/05.png" alt="" draggable="false">
        <img src="../assets/06.png" alt="" draggable="false">
        <img src="../assets/07.png" alt="" draggable="false"> -->
        <img src="../assets/yyzz.jpg" alt="" draggable="false">
      </div>
    </div>
    <div class="content_bottom">
      <div class="content_bottom_box">
        <div class="content_bottom_content center_dq">
          <a href="#">首页 </a>
          <span>|</span>
          <a href="javascript:;">联系方式 </a>
          <span>|</span>
          <a href="javascript:;">常见问题 </a>
          <span>|</span>
          <a href="https://beian.miit.gov.cn/#/Integrated/index">鲁ICP备20025936号-2</a>
          <a href="https://beian.miit.gov.cn/#/Integrated/index">
            <img src="../assets/umsbeianbgs.png" alt="">
          </a>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, onUnmounted } from "vue";

const dhList = reactive(['介绍', '应用场景', '收费标准', '下载'])
const swiperList = ref([])
let colorindex = ref(0)
// let MousDhindex = ref(0)
// let MousDhLeindex = ref(0)
const handleDh = (index) => {
  colorindex.value = index
  if (index == 0) {
    document.querySelector("#jieshao").scrollIntoView(true);
  } else if (index == 1) {
    document.querySelector("#yingyongchangjing").scrollIntoView(true);
  } else if (index == 2) {
    document.querySelector("#shoufeibz").scrollIntoView(true);
  } else {
    document.querySelector("#czzn").scrollIntoView(true);
  }
}

// window.onscroll = function () {
//   var scrcllTop = document.documentElement.scrollTop || document.body.scrollTop
//   console.log(scrcllTop)
//   that.msg = scrcllTop
// }
// const MousDh = (item, index) => {
//   MousDhindex.value = index
// }
// const MousLeDh = (item,index) =>{
//   MousDhLeindex.value = index
// }

</script>
<style lang="scss" scoped>
.box {
  width: 100%;

  .center_dq {
    width: 1200px;
    margin: 0 auto;
  }

  .box_top {
    background-color: #fff;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;

    .content_head {
      width: 100%;
      height: 30px;
      background-color: #111111;

      .content_head_center {
        height: 100%;

        p {
          color: #FFF;
          font-size: 13px;
          cursor: pointer;
        }

        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .content_top {
      width: 100%;
      height: 90px;
      border-bottom: 2px solid #DE3232;

      .content_top_content {
        display: flex;
        justify-content: space-between;
        height: 100%;

        .content_top_left {
          height: 100%;
          display: flex;
          align-items: center;

          img {
            border-radius: 10px;
            width: 60px;
            height: 60px;
            margin-right: 10px;
          }

          p {
            font-weight: 600;
          }
        }

        .content_top_center {
          display: flex;
          align-items: center;
          justify-content: space-between;

          ul {
            height: 100%;
            display: flex;
            align-items: center;

            .activeColor {
              color: #DA522A;
            }

            // .MousDhcolor{
            //   border-bottom: 1px solid #666;
            // }
            // .MousLeDhColor{
            //   border: none;
            // }

            li {
              text-align: center;
              margin-left: 50px;
              cursor: pointer;
              font-weight: 700;
              font-size: 16px;
              color: #6C6D6D;
            }
          }
        }

        .content_top_right {}
      }
    }
  }

  .content_center {
    .content_center_top {
      .el-carousel{
        // z-index: -1;
        .el-carousel__item {
        img {
          width: 100%;
          height:330px;
        }
      }
      }
    }
    .content_center_Gsjs{
      border: 2px solid #DA522A;
      box-shadow: 7px 7px 5px rgba($color: #DA522A, $alpha: 0.4);
      margin: 30px auto;
      padding: 20px;
      box-sizing: border-box;
      p{
        line-height: 35px;
        text-indent: 2em;
        font-size: 19px;
      }
    }

    .content_center_content {
      img {
        width: 100%;
        display: block;
      }
      img:last-child{
        margin: 0 auto;
        width: 50%;
      }
    }
  }

  .content_bottom {
    height: 60px;

    .content_bottom_box {
      // position: fixed;
      // bottom: 0;
      // left: 0;
      width: 100%;
      height: 60px;
      background-color: #000;

      .content_bottom_content {
        height: 100%;
        // padding-top: 10px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          color: #999;
          font-size: 12px;

          img {
            width: 20px;
            height: 20px;
            margin: 0px 10px;
          }
        }

        a:hover {
          border-bottom: 1px solid #999;
        }

        span {
          color: #999;
          font-size: 12px;
          margin: 0px 5px;
        }
      }
    }
  }
}
</style>
