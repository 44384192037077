<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}
ul{
  li{
    list-style: none;
  }
}
#app {
//  padding: 50px;
}
</style>
